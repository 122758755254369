// HowtoModal
// VERSION: 2.0.1

// [note: Overview]
// - The modal element uses Bootstrap modal component.
// - The following script only defines the behavior of the content inside the modal.
// - ※ HowtoModalは1ページにおいて1つしか存在しない要素として想定されています。

// [note: Usage]
// You only give data param like the following way on HTML.
//
// <div class="modal.." data-role="howto-modal">
// <div class="elements_you_want_to_use_as_next_pager" data-role="howto-modal-next-pager">
// <div class="elements_you_want_to_use_as_prev_pager" data-role="howto-modal-prev-pager">
// <div class="blocks_you_want_to_target_the_contents" data-role="howto-modal-content"> x n
//
// and adjust HTML
// - give `.d-none` to the second and subsequent `howto-modal-content`.
//
// and create and initialize instance..
// const howtoModal = new HowtoModal();
// howtoModal.init();

// [note: Usage (Optional)]
// If you don't want to use data params, you can use css classes or ID.
// The following example shows how to do this and how to use the other options.
//
// html:
// <div class="foo-modal">
// <div class="foo-next-pager">
// <div class="foo-prev-pager">
// <div class="foo-contents">
//
// js:
// const howtoModal = new HowtoModal({
//   selector: {
//     modal: '.foo-modal',
//     pagerNext: '.foo-next-pager',
//     pagerPrev: '.foo-prev-pager',
//     content: '.foo-contents',
//   },
//   config: {
//     pagerDisabledClassName: 'foo-disabled',
//     contentHideClassName: 'foo-d-none',
//   },
//   state: {
//     currentPageIndex: 1,
//   }
// });

class HowtoModal {
  constructor(opts) {
    if (!opts) opts = {};
    if (!opts.selector) opts.selector = {};
    if (!opts.selector.state) opts.state = {};
    if (!opts.selector.config) opts.config = {};
    this.version = '2.0.0';
    this.selector = {
      modal: opts.selector.modal
        ? document.querySelector(opts.selector.modal)
        : document.querySelector('[data-role="howto-modal"]'),
      pagerNext: opts.selector.pagerNext
        ? document.querySelector(opts.selector.pagerNext)
        : document.querySelector('[data-role="howto-modal-next-pager"]'),
      pagerPrev: opts.selector.pagerPrev
        ? document.querySelector(opts.selector.pagerPrev)
        : document.querySelector('[data-role="howto-modal-prev-pager"]'),
      contents: opts.selector.content
        ? document.querySelectorAll(opts.selector.content)
        : document.querySelectorAll('[data-role="howto-modal-content"]'),
    };

    this.config = {
      startPageIndex: 0,
      endPageIndex: this.selector.contents.length - 1,
      pagerDisabledClassName: opts.config.pagerDisabledClassName
        ? opts.config.pagerDisabledClassName
        : 'disabled',
      contentHideClassName: opts.config.contentHideClassName
        ? opts.config.contentHideClassName
        : 'd-none',
    };

    this.state = {
      currentPageIndex: opts.state.currentPageIndex
        ? opts.state.currentPageIndex
        : 0,
      nextPagerState: true,
      prevPagerState: false,
    };

    this.selector.pagerPrev.addEventListener('click', () => {
      this.countDownPageNum();
      this.updateDesign();
      this.updateContents();
    });

    this.selector.pagerNext.addEventListener('click', () => {
      this.countUpPageNum();
      this.updateDesign();
      this.updateContents();
    });
  }

  countUpPageNum() {
    if (this.state.currentPageIndex < this.config.endPageIndex) {
      this.state.currentPageIndex += 1;
    }
  }

  countDownPageNum() {
    if (this.state.currentPageIndex > this.config.startPageIndex) {
      this.state.currentPageIndex -= 1;
    }
  }

  updateDesign() {
    this.selector.pagerPrev.classList.remove(
      this.config.pagerDisabledClassName
    );
    this.selector.pagerNext.classList.remove(
      this.config.pagerDisabledClassName
    );
    if (this.state.currentPageIndex === this.config.startPageIndex) {
      this.selector.pagerPrev.classList.add(this.config.pagerDisabledClassName);
    }
    if (this.state.currentPageIndex === this.config.endPageIndex) {
      this.selector.pagerNext.classList.add(this.config.pagerDisabledClassName);
    }
  }

  updateContents() {
    Array.prototype.slice.call(this.selector.contents, 0).forEach((content) => {
      content.classList.add(this.config.contentHideClassName);
    });
    this.selector.contents[this.state.currentPageIndex].classList.remove(
      this.config.contentHideClassName
    );
  }

  init() {
    this.updateDesign();
    this.updateContents();
  }
}

export default HowtoModal;
