import Util from './Util';

export default class Pager {
  constructor(args = {}) {
    this.store = args.store;
    this.node = document.querySelector('[data-role="jalan-pager"]');

    document.querySelector('body').addEventListener('click', (e) => {
      if (e.target.dataset.role === 'jalan-pager-normal-item') {
        const pageNum = Number(e.target.dataset.pagenum);
        location.href = `${location.origin}${
          location.pathname
        }?${this.getQueryWithNewDisplayFromParam(pageNum)}`;
      }
    });
  }

  getQueryWithNewDisplayFromParam(num) {
    const queryHash = Util.getUrlQueryAsHash();
    const theDisplayFrom = Number(this.store.itemSizePerPage) * (num - 1) + 1;
    queryHash.displayFrom = theDisplayFrom;
    return Util.getQueryStrFromHash(queryHash);
  }

  render() {
    const allItemSize = this.store.fetchData.numberOfResults;
    const pagerItemLength = Math.ceil(allItemSize / this.store.itemSizePerPage);
    const crPageNum =
      Math.floor(
        this.store.fetchData.displayFrom / this.store.itemSizePerPage
      ) + 1;

    let tags = '';
    for (let i = 1; i <= pagerItemLength; i += 1) {
      if (i === crPageNum) {
        tags += `<li class="page-item mx-4px active"><span data-pagenum="${i}" data-role="jalan-pager-cr-item" class="page-link rounded-circle pager__item pager__item--active">${i}</span></li>`;
      } else {
        tags += `<li class="page-item mx-4px"><span data-pagenum="${i}" data-role="jalan-pager-normal-item" class="page-link rounded-circle pager__item cursor-pointer">${i}</span></li>`;
      }
    }
    this.node.innerHTML = tags;
  }
}
