import $ from 'jquery';
import Store from './Store';
import SortButtons from './SortButtons';
import Form from './Form';
import StatusText from './StatusText';
import Items from './Items';
import Pager from './Pager';
import Loading from './Loading';
import Util from './Util';

export default class JalanExperience {
  constructor(args = {}) {
    this.itemSizePerPage = args.itemSizePerPage || 24;
    // init store
    this.store = new Store({
      requestBaseURI: args.requestBaseURI || null,
      itemSizePerPage: this.itemSizePerPage,
      requestQuery: {
        kenCd: args.prefId || null,
        displayFrom: this.itemSizePerPage * (args.initPageNum - 1) + 1,
        displayCount: this.itemSizePerPage,
        activeSort: args.sortType || 1,
        ...Util.getUrlQueryAsHash(), // 既に定義済みのhashを持つ場合、上書き
      },
      view: {
        noImagePath: args.view.noImagePath,
        sortButtonActiveClassName:
          args.view.sortButtonActiveClassName || 'is-active',
      },
    });

    this.form = new Form({ store: this.store });
    this.statusText = new StatusText({ store: this.store });
    this.items = new Items({ store: this.store });
    this.pager = new Pager({ store: this.store });
    this.sortButtons = new SortButtons({ store: this.store });
    this.loading = new Loading();

    this.scrollPositionNode = document.querySelector(
      '[data-role="jalan-result-scroll-position"]'
    );

    // init view
    this.render();
  }

  updateStoreWithFetchData(fetchData, fetchStatus) {
    // fetchしてきたデータをstoreに丸々コピー
    this.store.fetchData = fetchData;
    this.store.fetchStatus = fetchStatus;

    // fetch内容の状態を示す補助storeを更新
    if (Number(fetchData.result) === 1) {
      this.store.jalanStatus = 'invalidRequest';
    } else if (
      Number(fetchData.result) === 0 &&
      Number(fetchData.numberOfResults) === 0
    ) {
      this.store.jalanStatus = 'noItem';
    } else if (Number(fetchData.result) === 2) {
      this.store.jalanStatus = 'systemError';
    } else {
      this.store.jalanStatus = 'hasItem';
    }
  }

  scrollResultLocation(args = {}) {
    document.documentElement.scrollTop = 0;
    const destinationLoc =
      this.scrollPositionNode.getBoundingClientRect().top - args.offset;
    const unitOfMoving = 10;
    const duration = 5;
    let sum = 0;
    let scrollId;

    const scrollFn = () => {
      sum += unitOfMoving;
      if (sum >= destinationLoc) {
        document.documentElement.scrollTop = destinationLoc;
        clearInterval(scrollId);
        return;
      }
      document.documentElement.scrollTop = sum;
    };
    scrollId = setInterval(scrollFn, duration);
  }

  render() {
    this.loading.show();
    $.ajax({
      url: `${this.store.requestBaseURI}?${Util.getQueryStrFromHash(
        this.store.requestQuery
      )}`,
      dataType: 'json',
      success: (data, status) => {
        // update store
        this.updateStoreWithFetchData(data, status);

        // debug
        // console.log('storeは => ', this.store);
        // console.log(
        //   'リクエストしたURLは => ',
        //   `${this.store.requestBaseURI}?${Util.getQueryStrFromHash(
        //     this.store.requestQuery
        //   )}`
        // );

        // render
        this.form.render();
        this.statusText.render();
        this.sortButtons.render();
        this.items.render();
        this.pager.render();

        this.loading.hide();
        if (window.location.search) this.scrollResultLocation({ offset: 200 });
      },
      error: (data, status) => {
        // サーバにてエラーがあった場合、画面にエラーメッセージを表示する
        // じゃらんAPIのresultは 0:正常 1:エラーの2パターンの為、新たに2を定義し、システムエラーとして扱います
        this.updateStoreWithFetchData({ result: 2 }, data.status);
        this.statusText.render();
        this.loading.hide();
      },
    });
  }
}
