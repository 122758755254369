export default class Store {
  constructor(opts = {}) {
    this._masterInstance = null;
    this._crId = opts.crId || 'a';
    this._listMode = 'vertical';
    this._nodeName = {
      master: '[data-role="markerAndListSync"]',
      marker: '[data-role="marker"]',
      lists: '[data-role="listWrapper"]',
      list: '[data-role="list"]',
    };
    this._className = {
      markerActive: 'marker-and-list-sync-marker-active',
      listActive: 'marker-and-list-sync-list-active',
    };
  }
  get masterInstance() {
    return this._masterInstance;
  }
  set masterInstance(val) {
    this._masterInstance = val;
  }
  get crId() {
    return this._crId;
  }
  set crId(val) {
    this._crId = val;
  }
  get listMode() {
    return this._listMode;
  }
  set listMode(val) {
    this._listMode = val;
  }
  get nodeName() {
    return this._nodeName;
  }
  get className() {
    return this._className;
  }
  set className(val) {
    this._className = val;
  }
}
