import $ from 'jquery';
import objectFitImages from 'object-fit-images';
import Swiper from 'swiper/bundle';
import AicUi from './libs/AicUi';
import PageScrollUsingSelector from './utils/PageScrollUsingSelector';
import Header from './components/Header';
import Footer from './components/Footer';
import HowtoModal from './components/HowtoModal';
// import UrgentNewsModal from './components/UrgentNewsModal';
import MarkerAndListSync from './components/MarkerAndListSync/MarkerAndListSync';
import JalanExperience from './components/JalanExperience/JalanExperience';
import Loader from './components/Loader';
import TwitterWidgetManager from './components/TwitterWidgetManager';
import FacebookWidgetManager from './components/FacebookWidgetManager';

// -----------------------------------
// Global utility style class
// -----------------------------------

// Object Fit Style Polyfill for IE11
// [doc] https://github.com/fregante/object-fit-images
if (document.querySelector('.object-fit-cover-cc') !== null) {
  objectFitImages(document.querySelectorAll('.object-fit-cover-cc'));
}

if (document.querySelector('.object-fit-contain-cc') !== null) {
  objectFitImages(document.querySelectorAll('.object-fit-contain-cc'));
}

// -----------------------------------
// Global layout parts
// -----------------------------------

// [note] 第3階層のページのみ、headerは常にbasicモード
if (Number(document.querySelector('body').dataset.isShowPage)) {
  const siteHeader = new Header({ isAllTimeBasicViewMode: true });
} else {
  const siteHeader = new Header();
}

const siteFooter = new Footer();

// -----------------------------------
// Global component parts
// -----------------------------------

// ページヘッダー内のJTP説明用のモーダルの制御
if (document.querySelector('[data-role="howto-modal"]') !== null) {
  const howToModal = new HowtoModal();
  howToModal.init();
}

// if (document.querySelector('[data-role="urgent-news-modal"]') !== null) {
//   const urgentNewsModal = new UrgentNewsModal();
//   urgentNewsModal.init();
// }

// -----------------------------------
// Shared parts
// -----------------------------------

// サブページのスライド式メインビジュアル用のswiper
if (document.querySelector('.sub-page-mv-swiper-container') !== null) {
  const subPageMvSwiper = new Swiper('.sub-page-mv-swiper-container.swiper-container', {
    slidesPerColumn: 1,
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
    speed: 1000,
    autoplay: {
      delay: 4000,
    },
    pagination: {
      el: '.sub-page-mv-swiper-container .swiper-pagination',
      clickable: true,
    },
    loop: true,
  });
}

// 検索フォームが存在するページにおいて、urlにクエリが存在する場合はスクロール処理を入れる
// spots index & courses index & events index 共通
//
// [note 2022.08.01]
// 旧仕様においてローダーの表示非表示に「LoadListener.js」というスクリプトを間に挟んで処理していたが、
// 現時点でこれは必要ないと判断するため削除した。
if (document.querySelector('#postFormSearchResult') !== null && window.location.search) {
  const loader = new Loader();
  const scroller = new PageScrollUsingSelector({
    destinationSelectorName: '#postFormSearchResult',
    offset: -320,
  });

  // [note] 現仕様においてはロードしている雰囲気を出すためにローダーを配置しているにすぎない
  loader.show();
  setTimeout(() => {
    scroller.scroll();
    loader.hide();
  }, 300);
}

// AICとJTPのページにおけるアラート処理 for IE11
(() => {
  function showIeBrowserErrorAlert() {
    const errorMsg =
      'こちらのページの閲覧推奨ブラウザは、Chrome, Safari, Firefox です。IE11は非対応となっており、推奨のブラウザのご利用をお願いいたします。';
    const userAgent = window.navigator.userAgent.toUpperCase();
    if (userAgent.indexOf('MSIE') !== -1 || userAgent.indexOf('TRIDENT') === -1) {
      console.log('not IE');
    } else {
      alert(errorMsg);
    }
  }

  // 処理はIE11に対応していない特定のページに限る
  if (document.querySelector('.japan-concierge') !== null || document.querySelector('#page_conciergesIndex') !== null) {
    showIeBrowserErrorAlert();
  }
})();

// -----------------------------------
// For page
// -----------------------------------

// spot一覧用のCarousel
if (document.querySelector('.page-carousel-swiper-container') !== null) {
  // eslint-disable-next-line no-unused-vars
  const spotsShowSwiper = new Swiper('.page-carousel-swiper-container .swiper-container', {
    slidesPerView: 1,
    spaceBetween: 30,
    centeredSlides: true,
    loop: true,
    speed: 600,
    navigation: {
      nextEl: '.page-carousel-swiper-container .swiper-btn--type_next',
      prevEl: '.page-carousel-swiper-container .swiper-btn--type_prev',
    },
    breakpoints: {
      1200: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      992: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      640: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
      420: {
        slidesPerView: 1,
        spaceBetween: 30,
      },
    },
  });
}

// spot一覧用のCarousel(関連するブランニューアキタ)
if (document.querySelector('.page-carousel-swiper-container-brandnew') !== null) {
  const slideLength = document.querySelectorAll('.page-carousel-swiper-container-brandnew .swiper-slide').length;
  const swiperElm = document.querySelector('.page-carousel-swiper-container-brandnew');

  if (slideLength === 1) {
    swiperElm.classList.add('page-carousel-swiper-container-brandnew--type_col-1');

    const slidesPerView = 1;
    const isSlidable =
      document.querySelector('.page-carousel-swiper-container-brandnew .swiper-slide').length > slidesPerView;
    const containerModifierClass = isSlidable ? 'is-active swiper-container-' : 'swiper-container-';
    // eslint-disable-next-line no-unused-vars
    const topAreasSwiper = new Swiper('.page-carousel-swiper-container-brandnew .swiper-container', {
      containerModifierClass,
      speed: 400,
      spaceBetween: 40,
      slidesPerView,
      loop: isSlidable,
      navigation: {
        nextEl: '.page-carousel-swiper-container-brandnew .swiper-btn--type_next',
        prevEl: '.page-carousel-swiper-container-brandnew .swiper-btn--type_prev',
      },
      watchOverflow: true,
    });
  }

  if (slideLength === 2) {
    swiperElm.classList.add('page-carousel-swiper-container-brandnew--type_col-2');

    const slidesPerView = 1;
    const isSlidable =
      document.querySelector('.page-carousel-swiper-container-brandnew .swiper-slide').length > slidesPerView;
    const containerModifierClass = isSlidable ? 'is-active swiper-container-' : 'swiper-container-';
    // eslint-disable-next-line no-unused-vars
    const topAreasSwiper = new Swiper('.page-carousel-swiper-container-brandnew .swiper-container', {
      containerModifierClass,
      speed: 400,
      spaceBetween: 40,
      slidesPerView,
      loop: true,
      navigation: {
        nextEl: '.page-carousel-swiper-container-brandnew .swiper-btn--type_next',
        prevEl: '.page-carousel-swiper-container-brandnew .swiper-btn--type_prev',
      },
      watchOverflow: true,
      breakpoints: {
        1200: {
          slidesPerView: 3,
          spaceBetween: 20,
          loop: isSlidable,
        },
        992: {
          slidesPerView: 2,
          spaceBetween: 20,
          loop: isSlidable,
        },
        640: {
          slidesPerView: 2,
          spaceBetween: 10,
          loop: isSlidable,
        },
        420: {
          slidesPerView: 1,
          spaceBetween: 30,
        },
      },
    });
  }

  if (slideLength === 3) {
    swiperElm.classList.add('page-carousel-swiper-container-brandnew--type_col-2');

    const slidesPerView = 1;
    const isSlidable =
      document.querySelector('.page-carousel-swiper-container-brandnew .swiper-slide').length > slidesPerView;
    const containerModifierClass = isSlidable ? 'is-active swiper-container-' : 'swiper-container-';
    // eslint-disable-next-line no-unused-vars
    const topAreasSwiper = new Swiper('.page-carousel-swiper-container-brandnew .swiper-container', {
      containerModifierClass,
      speed: 400,
      spaceBetween: 40,
      slidesPerView,
      loop: true,
      navigation: {
        nextEl: '.page-carousel-swiper-container-brandnew .swiper-btn--type_next',
        prevEl: '.page-carousel-swiper-container-brandnew .swiper-btn--type_prev',
      },
      watchOverflow: true,
      breakpoints: {
        1200: {
          slidesPerView: 3,
          spaceBetween: 20,
          loop: isSlidable,
        },
        992: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        640: {
          slidesPerView: 2,
          spaceBetween: 10,
        },
        420: {
          slidesPerView: 1,
          spaceBetween: 30,
        },
      },
    });
  }

  if (slideLength > 3) {
    const swiperElm = document.querySelector('.page-carousel-swiper-container-brandnew');

    // eslint-disable-next-line no-unused-vars
    const spotsShowSwiper = new Swiper('.page-carousel-swiper-container-brandnew .swiper-container', {
      slidesPerView: 1,
      spaceBetween: 30,
      centeredSlides: true,
      loop: true,
      speed: 600,
      navigation: {
        nextEl: '.page-carousel-swiper-container-brandnew .swiper-btn--type_next',
        prevEl: '.page-carousel-swiper-container-brandnew .swiper-btn--type_prev',
      },
      breakpoints: {
        1200: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        992: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        640: {
          slidesPerView: 2,
          spaceBetween: 10,
        },
        420: {
          slidesPerView: 1,
          spaceBetween: 30,
        },
      },
    });
  }
}

// top page areas section Carousel
if (document.querySelector('.top-areas-carousel-swiper-container') !== null) {
  const slidesPerView = 1;
  const isSlidable =
    document.querySelector('.top-areas-carousel-swiper-container .swiper-slide').length > slidesPerView;
  const containerModifierClass = isSlidable ? 'is-active swiper-container-' : 'swiper-container-';
  // eslint-disable-next-line no-unused-vars
  const topAreasSwiper = new Swiper('.top-areas-carousel-swiper-container .swiper-container', {
    containerModifierClass,
    speed: 400,
    spaceBetween: 40,
    slidesPerView,
    loop: isSlidable,
    navigation: {
      nextEl: '.top-areas-carousel-swiper-container .swiper-btn--type_next',
      prevEl: '.top-areas-carousel-swiper-container .swiper-btn--type_prev',
    },
    watchOverflow: true,
    breakpoints: {
      1200: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      992: {
        slidesPerView: 2,
        spaceBetween: 20,
        loop: true,
      },
      640: {
        slidesPerView: 2,
        spaceBetween: 10,
        loop: true,
      },
      420: {
        slidesPerView: 1,
        spaceBetween: 30,
        loop: true,
      },
    },
  });
}

// topページのmovieエリア
if (document.querySelector('#topMovieModal') !== null) {
  // サムネイルクリック時のモーダル内動画src指定
  Array.prototype.slice.call(document.querySelectorAll('.js-top-movie-modal-trigger'), 0).forEach((node) => {
    node.addEventListener('click', () => {
      const { url } = node.dataset;
      document.querySelector('#topYoutubeMovie').src = url;
    });
  });
  // モーダル閉じた時のモーダル内動画src空に
  $('#topMovieModal').on('hide.bs.modal', () => {
    document.querySelector('#topYoutubeMovie').src = '';
  });
}

// topページのmapエリアのパーツ
if (document.querySelector('[data-role="markerAndListSync"]') !== null) {
  const markerAndListSync = new MarkerAndListSync({
    crId: 'a',
    className: {
      markerActive: 'top-map__marker--status_active',
      listActive: 'top-map__list--status_active',
    },
  });
}

// For AI Plan Page
if (document.querySelector('[data-role="aic-form"]') !== null) {
  const aicUi = new AicUi({
    apikey: 'd0wI2Hldi2itkfCPzR4j', // dummmy
    place: 'akita_pref', // dummmy
    aicAPIBaseURI: '/aiplan.js',
    defaultLang: 'ja',
    crLang: 'ja',
    styles: {
      radarInnerFillColor: '#a3bffa',
    },
    chartParams: {
      labels: {
        ja: ['Ａ.体験', 'Ｂ.歴史・文化', 'Ｃ.買い物', 'Ｄ.癒し', 'Ｅ.自然'],
      },
    },
  });
  aicUi.init();
}

// reservationページのじゃらん体験
if (document.querySelector('[data-role="jalan-form"]') !== null) {
  const jalanExperience = new JalanExperience({
    requestBaseURI: '/jalan_experiences',
    prefId: '050000',
    itemSizePerPage: 24,
    initPageNum: 1,
    sortType: 1,
    view: {
      noImagePath: document.querySelector('[data-role="jalan-no-img-path"]').src,
      sortButtonActiveClassName: '--active',
    },
  });
}

// bookingページ: たびくらフォームのサブミットの定義
if (document.querySelector('[data-role="booking-form"]') !== null) {
  document.querySelector('[data-role="booking-submit"]').addEventListener('click', (e) => {
    // Formより参照した値を利用
    const url = document.bookingForm.action;
    const prefVal = document.bookingForm.pf.value;
    const sectionVal = document.bookingForm.rg.value;
    const areaVal = document.bookingForm.ar.value;
    const numberOfPeopleVal = document.bookingForm.cp.value;
    const numberOfRoomVal = document.bookingForm.rn.value;
    const minBudgetVal = document.bookingForm.pn.value;
    const maxBudgetVal = document.bookingForm.px.value;
    const roomTypeVal = document.bookingForm.rt.value;
    const mealTypeVal = document.bookingForm.mc.value;
    const keywordsVal = document.bookingForm.kw.value;

    // Formから参照した値を加工し利用
    const dateVal = document.bookingForm.sd.value.replace(/-/g, '');
    const kodawariNodes = document.bookingForm.kd;
    const kodawariVals = [];
    Array.prototype.slice.call(kodawariNodes, 0).forEach((node) => {
      if (node.checked) kodawariVals.push(node.value);
    });

    const requestURL = `${url}?pf=${prefVal}&rg=${sectionVal}&ar=${areaVal}&sd=${dateVal}&cp=${numberOfPeopleVal}&rn=${numberOfRoomVal}&pn=${minBudgetVal}&px=${maxBudgetVal}&rt=${roomTypeVal}&mc=${mealTypeVal}&kw=${keywordsVal}&kd=${kodawariVals}`;

    // debug
    // console.log(requestURL);

    window.location.href = requestURL;
    return false;
  });
}

// for top page SNS widgets
if (
  document.querySelector('[data-js-role="facebook-widget-wrap"]') !== null &&
  document.querySelector('[data-js-role="twitter-widget-wrap"]') !== null
) {
  // [note]
  // TwitterのウィジェットのみPageSpeed Insightのwebp関連の改善項目に引っ掛かるので
  //  遅延読み込みする
  let inActiveTwitterWedget = true;
  const offsetYRectTop = -700;
  window.addEventListener('scroll', () => {
    const twitterWidgetRectTop = document
      .querySelector('[data-js-role="twitter-widget-wrap"]')
      .getBoundingClientRect().top;
    const targetYLocation = twitterWidgetRectTop + offsetYRectTop;

    // 所定の場所に到達したらtwitter weget用のscriptを読み込む
    if (targetYLocation < 0 && inActiveTwitterWedget) {
      inActiveTwitterWedget = false;
      const script = document.createElement('script');
      script.src = 'https://platform.twitter.com/widgets.js';
      document.body.appendChild(script);
    }
  });

  // [note]
  // ウィジェットはレスポンシブに対応していないため
  // windowのリサイズの際にウィジェットの見た目を監視するスクリプトを用意する

  // eslint-disable-next-line
  new FacebookWidgetManager({
    elm: document.querySelector('.fb-page'),
    bp: 1132,
    sizeL: {
      w: 400,
      h: 600,
    },
    sizeS: {
      w: 300,
      h: 500,
    },
  });
  // eslint-disable-next-line
  new TwitterWidgetManager({
    wrapElm: document.querySelector('[data-js-role="twitter-widget-wrap"]'),
    bp: 1132,
    sizeL: {
      w: 400,
      h: 600,
    },
    sizeS: {
      w: 300,
      h: 500,
    },
  });
}

// tab
//
// 汎用 jquery利用
if (document.querySelector('#tab-cmn') !== null) {
  $(() => {
    $('.tab-cmn-menu__item').on('click', function () {
      let tabWrap = $(this).parents('#tab-cmn');
      let tabBtn = tabWrap.find('.tab-cmn-menu__item');
      let tabContents = tabWrap.find('.tab-cmn-contents');
      tabBtn.removeClass('is-active');
      $(this).addClass('is-active');
      let elmIndex = tabBtn.index(this);
      tabContents.removeClass('is-active');
      tabContents.eq(elmIndex).addClass('is-active');
    });
  });
}
