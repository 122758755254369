// FacebookWidgetManager
//
// VERSION: v0.1.0
// WRITEN_BY: masa-suimoto (masa-sumimoto@gmail.com)
// DESCRIPTION: Facebookの埋め込みタイムラインの読み込みをリサイズ(レスポンシブ)などのイベントに対し最適化する
//
// OPTIONS: コンストラクタに渡す単一のオブジェクトのメンバの詳細
// elm: string => Facebookのウィジェットiframe自身
// pb: number => この値以上を「Lサイズ」と定義。値未満は「Sサイズ」
// sizeL: object => webサイトがLサイズ時のelmのスタイル定義
// sizeL.w: number => Lサイズ時のelmのwidth
// sizeL.h: number => Lサイズ時のelmのheight
// sizeS: object => webサイトがSサイズ時のelmのスタイル定義
// sizeS.w: number => Sサイズ時のelmのwidth
// sizeS.h: number => Sサイズ時のelmのheight
// resizeDelayTime: number => windowのリサイズ時の際に実行されるスクリプトの遅延時間。あまり長いと素早いリサイズ行為に対し、windowのサイズ取得の処理を読み飛ばす傾向にある。なのでデフォルト値100を特段変える必要無し

class FacebookWidgetManager {
  constructor({ elm, bp, sizeL, sizeS, resizeDelayTime }) {
    this.elm = elm;
    this.bp = bp || 992;
    this.sizeL = {
      w: sizeL ? sizeL.w : 400,
      h: sizeL ? sizeL.h : 600,
    };
    this.sizeS = {
      w: sizeS ? sizeS.w : 300,
      h: sizeS ? sizeS.h : 500,
    };
    this.timerId = null;
    this.resizeDelayTime = resizeDelayTime || 100;
    this.state = {
      sizeFlg: window.innerWidth >= this.bp ? 'L' : 'S',
      timerId: null,
    };

    this.reloadWidget = () => {
      if (window.FB && window.FB.XFBL) {
        window.FB.XFBML.parse();
      }
    };

    window.addEventListener('load', () => {
      this.handleLoad();
    });

    window.addEventListener('resize', () => {
      this.handleResize();
    });
  }

  handleLoad() {
    this.render();
  }

  handleResize() {
    const nowSize = window.innerWidth >= this.bp ? 'L' : 'S';
    if (nowSize !== this.state.sizeFlg) this.render();
  }

  delay(...fns) {
    if (this.state.timerId) return;
    this.state.timerId = setTimeout(() => {
      this.state.timerId = null;
      for (let i = 0; i < fns.length; i += 1) {
        const fn = fns[i];
        fn();
      }
    }, this.resizeDelayTime);
  }

  setSizeL() {
    if (this.elm && this.elm.dataset) {
      this.elm.dataset.width = this.sizeL.w;
      this.elm.dataset.height = this.sizeL.h;
    }
  }

  setSizeS() {
    if (this.elm && this.elm.dataset) {
      this.elm.dataset.width = this.sizeS.w;
      this.elm.dataset.height = this.sizeS.h;
    }
  }

  setSizeFlgState() {
    this.state.sizeFlg = window.innerWidth >= this.bp ? 'L' : 'S';
  }

  render() {
    if (window.innerWidth >= this.bp) {
      this.delay(this.setSizeL.bind(this), this.reloadWidget.bind(this), this.setSizeFlgState.bind(this));
    } else {
      this.delay(this.setSizeS.bind(this), this.reloadWidget.bind(this), this.setSizeFlgState.bind(this));
    }
  }
}

export default FacebookWidgetManager;
