export default class Loading {
  constructor() {
    this.node = document.querySelector('[data-role="jalan-loading"]');
  }

  show() {
    this.node.classList.remove('d-none');
  }

  hide() {
    setTimeout(() => {
      this.node.classList.add('d-none');
    }, 200);
  }
}
