// TwitterWidgetManager
//
// VERSION: v0.1.0
// WRITEN_BY: masa-suimoto (masa-sumimoto@gmail.com)
// DESCRIPTION: Twitterの埋め込みタイムラインの読み込みをリサイズ(レスポンシブ)などのイベントに対し最適化する
//
// OPTIONS: コンストラクタに渡す単一のオブジェクトのメンバの詳細
// wrapElm: string => Facebookのウィジェットiframeと差し代わるaタグ自身をラップするhtmlエレメント
// pb: number => この値以上を「Lサイズ」と定義。値未満は「Sサイズ」
// sizeL: object => webサイトがLサイズ時のelmのスタイル定義
// sizeL.w: number or string => Lサイズ時のelmのwidth
// sizeL.h: number or string => Lサイズ時のelmのheight
// sizeS: object => webサイトがSサイズ時のelmのスタイル定義
// sizeS.w: number or string => Sサイズ時のelmのwidth
// sizeS.h: number or string => Sサイズ時のelmのheight
// resizeDelayTime: number => windowのリサイズ時の際に実行されるスクリプトの遅延時間。あまり長いと素早いリサイズ行為に対し、windowのサイズ取得の処理を読み飛ばす傾向にある。なのでデフォルト値100を特段変える必要無し

class TwitterWidgetManager {
  constructor({ wrapElm, bp, sizeL, sizeS, resizeDelayTime }) {
    this.wrapElm = wrapElm;
    this.bp = bp || 992;
    this.sizeL = {
      w: sizeL ? sizeL.w : '400px',
      h: sizeL ? sizeL.h : '600px',
    };
    this.sizeS = {
      w: sizeS ? sizeS.w : '300px',
      h: sizeS ? sizeS.h : '500px',
    };

    // [note] 性質上TwitterはFacebookと違い、ウィジェットのスタイル成形のためのwidth, height指定にcss styleを利用できる。
    // すなわち '200px', 'auto', '100%' などである。ただ兄弟スクリプトであるFacebookWidgetと使用感を統一するため
    // ユーザーはインスタンス生成時には数値を利用すると良い。そのため以下、それを想定した処理を別途追加している。
    this.sizeL.w = typeof this.sizeL.w === 'number' ? `${this.sizeL.w}px` : this.sizeL.w;
    this.sizeL.h = typeof this.sizeL.h === 'number' ? `${this.sizeL.h}px` : this.sizeL.h;
    this.sizeS.w = typeof this.sizeS.w === 'number' ? `${this.sizeS.w}px` : this.sizeS.w;
    this.sizeS.h = typeof this.sizeS.h === 'number' ? `${this.sizeS.h}px` : this.sizeS.h;

    this.timerId = null;
    this.resizeDelayTime = resizeDelayTime || 100;

    this.state = {
      sizeFlg: window.innerWidth >= this.bp ? 'L' : 'S',
      timerId: null,
    };

    window.addEventListener('load', () => {
      this.handleLoad();
    });

    window.addEventListener('resize', () => {
      this.handleResize();
    });
  }

  handleLoad() {
    this.render();
  }

  handleResize() {
    const nowSize = window.innerWidth >= this.bp ? 'L' : 'S';
    if (nowSize !== this.state.sizeFlg) this.render();
  }

  delay(...fns) {
    if (this.state.timerId) return;
    this.state.timerId = setTimeout(() => {
      this.state.timerId = null;
      for (let i = 0; i < fns.length; i += 1) {
        const fn = fns[i];
        fn();
      }
    }, this.resizeDelayTime);
  }

  setSizeL() {
    const widget = this.wrapElm.querySelector('iframe');
    if (widget) {
      widget.style.width = this.sizeL.w;
      widget.style.height = this.sizeL.h;
    }
  }

  setSizeS() {
    const widget = this.wrapElm.querySelector('iframe');
    if (widget) {
      widget.style.width = this.sizeS.w;
      widget.style.height = this.sizeS.h;
    }
  }

  setSizeFlgState() {
    this.state.sizeFlg = window.innerWidth >= this.bp ? 'L' : 'S';
  }

  render() {
    if (window.innerWidth >= this.bp) {
      this.delay(this.setSizeL.bind(this), this.setSizeFlgState.bind(this));
    } else {
      this.delay(this.setSizeS.bind(this), this.setSizeFlgState.bind(this));
    }
  }
}

export default TwitterWidgetManager;
