import $ from 'jquery';

export default class PageScrollUsingSelector {
  constructor(args = {}) {
    this.$destinationNode = $(args.destinationSelectorName);
    this.offset = args.offset || 0;
  }

  scroll() {
    const nodeTop = this.$destinationNode.offset().top;
    $('html,body').animate({ scrollTop: nodeTop + this.offset }, 'slow');
  }
}
