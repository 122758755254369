// ----------------------------
// [note]
// Listsクラスと対応したHTML要素には必ず、スタイルシートで
// 以下をスタイリングしておくこと
//
// verticalモード時↓
// overflow-x: hidden;
// overflow-y: auto;
//
// horizonモード時↓
// overflow-x: auto;
// overflow-y: hidden;
// ----------------------------

import Store from './Store';
import Markers from './Markers';
import Lists from './Lists';

export default class MarkerAndListSync {
  constructor(opts = {}) {
    // 全class instance間で使い回すデータを生成 & 格納
    this.store = new Store();
    this.store.masterInstance = this;

    // 初期化の際のstoreの書き換え指定を反映
    if (opts.crId) this.store.crId = opts.crId;
    if (opts.className) {
      if (opts.className.markerActive)
        this.store.className.markerActive = opts.className.markerActive;
      if (opts.className.listActive)
        this.store.className.listActive = opts.className.listActive;
    }

    // 自身のnode及び、サブラッパーたちのインスタンスはこのメインクラスで管理
    this.node = document.querySelector(this.store.nodeName.master);
    this.markers = new Markers({ store: this.store });
    this.lists = new Lists({ store: this.store });

    this.render();

    window.addEventListener('resize', () => {
      this.handleClick();
    });
  }

  handleClick() {
    this.render();
  }

  updateStore() {
    this.store.listMode = this.getListModeFromCss();
  }

  render() {
    this.updateStore();
    this.markers.render();
    this.lists.render();
  }

  getListModeFromCss() {
    const listNode = document.querySelector(this.store.nodeName.lists);
    // リストが縦スクロールモードか横スクロールモードかをcssから判別
    const overflowY = window.getComputedStyle(listNode).overflowY;
    const overflowX = window.getComputedStyle(listNode).overflowX;
    if (
      (overflowY === 'auto' || overflowY === 'scroll') &&
      overflowX === 'hidden'
    ) {
      return 'vertical';
    }
    if (
      (overflowX === 'auto' || overflowX === 'scroll') &&
      overflowY === 'hidden'
    ) {
      return 'horizon';
    }
    return false;
  }

  // static getInstance() {
  //   if (!this.instance) this.instance = new MarkerAndListSync();
  //   return this.instance;
  // }
}
