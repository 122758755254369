import List from './List';

export default class Lists {
  constructor(opts = {}) {
    this.store = opts.store;
    this.node = document.querySelector(
      `${this.store.nodeName.master} ${this.store.nodeName.lists}`
    );
    this.children = [];

    Array.prototype.slice
      .call(
        document.querySelectorAll(
          `${this.store.nodeName.master} ${this.store.nodeName.list}`
        ),
        0
      )
      .forEach((node) => {
        this.children.push(new List({ store: this.store, node: node }));
      });
    this.scrollAnimInterval = 10;
    this.scrollAnimSteps = 20; // アニメーションを何段階で刻むか
  }

  inActiveLists() {
    this.children.forEach((list) => {
      list.inactive();
    });
  }

  activeCrList() {
    this.children.forEach((list) => {
      if (list.id === this.store.crId) list.active();
    });
  }

  adjustCrListYpositionByScrolling() {
    this.children.forEach((list) => {
      if (list.id === this.store.crId) {
        const wrapperRectTop = this.node.getBoundingClientRect().top;
        const wrapperScrollTop = this.node.scrollTop;
        const itemRectTop = list.node.getBoundingClientRect().top;
        const itemHeight = list.node.clientHeight;
        const wrapperHeight = this.node.clientHeight;
        const offset = wrapperHeight / 2 - itemHeight / 2;
        const arrivalVerticalLoc =
          itemRectTop - wrapperRectTop + wrapperScrollTop - offset;
        let movingDistance = arrivalVerticalLoc - wrapperScrollTop;
        let vector = null;
        // 現在地と到達地の「方向」を決定する。
        // またmovingDistanceは単に二点間の差を保持しているため、
        // マイナス値を保持している場合はプラス値に変換
        if (movingDistance >= 0) {
          vector = 'down';
        } else {
          movingDistance = movingDistance * -1;
          vector = 'up';
        }
        const unitOfMoving = movingDistance / this.scrollAnimSteps;
        let sum = 0;
        const scrollFn = () => {
          sum += unitOfMoving;
          // 合計距離が既に目的の数量に達するとき
          if (sum >= movingDistance) {
            this.node.scrollTop = arrivalVerticalLoc;
            clearInterval(scrollId);
            return;
          }
          // 現在のカレントアイテムよりも下のものを選ぶ場合
          if (vector === 'down') {
            this.node.scrollTop += unitOfMoving;
          }
          // 現在のカレントアイテムよりも上のものを選ぶ場合
          if (vector === 'up') {
            this.node.scrollTop -= unitOfMoving;
          }
        };
        const scrollId = setInterval(scrollFn, this.scrollAnimInterval);
      }
    });
  }

  adjustCrListXpositionByScrolling() {
    this.children.forEach((list) => {
      if (list.id === this.store.crId) {
        const wrapperRectLeft = this.node.getBoundingClientRect().left;
        const wrapperScrollLeft = this.node.scrollLeft;
        const itemRectLeft = list.node.getBoundingClientRect().left;
        const itemWidth = list.node.clientWidth;
        const wrapperWidth = this.node.clientWidth;
        const offset = wrapperWidth / 2 - itemWidth / 2;
        const arrivalHrizonLoc =
          itemRectLeft - wrapperRectLeft + wrapperScrollLeft - offset;
        let movingDistance = arrivalHrizonLoc - wrapperScrollLeft;
        let vector = null;
        // 現在地と到達地の「方向」を決定する。
        // またmovingDistanceは単に二点間の差を保持しているため、
        // マイナス値を保持している場合はプラス値に変換
        if (movingDistance >= 0) {
          vector = 'left';
        } else {
          movingDistance = movingDistance * -1;
          vector = 'right';
        }
        const unitOfMoving = movingDistance / this.scrollAnimSteps;
        let sum = 0;
        const scrollFn = () => {
          sum += unitOfMoving;
          // 合計距離が既に目的の数量に達するとき
          if (sum >= movingDistance) {
            this.node.scrollLeft = arrivalHrizonLoc;
            clearInterval(scrollId);
            return;
          }
          // 現在のカレントアイテムよりも右のものを選ぶ場合
          if (vector === 'left') {
            this.node.scrollLeft += unitOfMoving;
          }
          // 現在のカレントアイテムよりも左のものを選ぶ場合
          if (vector === 'right') {
            this.node.scrollLeft -= unitOfMoving;
          }
        };
        const scrollId = setInterval(scrollFn, this.scrollAnimInterval);
      }
    });
  }

  render() {
    this.inActiveLists();
    this.activeCrList();
    if (this.store.listMode === 'vertical') {
      this.adjustCrListYpositionByScrolling();
    } else if (this.store.listMode === 'horizon') {
      this.adjustCrListXpositionByScrolling();
    }
  }
}
