// [note]
// data for all classes.
// Classes get and set only from this class instance.
export default class JalanExperienceStore {
  constructor(args = {}) {
    this.requestBaseURI = args.requestBaseURI;
    this.itemSizePerPage = args.itemSizePerPage;
    this.requestQuery = args.requestQuery;
    this.fetchData = null;
    this.fetchStatus = null;
    this.jalanStatus = null;
    // user design option
    this.view = {};
    this.view.noImagePath = args.view.noImagePath;
    this.view.sortButtonActiveClassName = args.view.sortButtonActiveClassName;
    this.view.pagerItemActiveClassName = args.view.pagerItemActiveClassName;
  }
}
