import Marker from './Marker';

export default class Markers {
  constructor(opts = {}) {
    this.store = opts.store;
    this.children = [];
    Array.prototype.slice
      .call(
        document.querySelectorAll(
          `${this.store.nodeName.master} ${this.store.nodeName.marker}`
        ),
        0
      )
      .forEach((node) => {
        this.children.push(new Marker({ store: this.store, node: node }));
      });
  }

  inActiveMarkers() {
    this.children.forEach((marker) => {
      marker.inactive();
    });
  }

  activeCrMarker() {
    this.children.forEach((marker) => {
      if (marker.id === this.store.crId) marker.active();
    });
  }

  render() {
    this.inActiveMarkers();
    this.activeCrMarker();
  }
}
