export default class Loader {
  constructor(args = {}) {
    this.roleId = args.roleId || 'loader-instance';
  }

  show() {
    const node = document.createElement('div');
    node.dataset.role = this.roleId;
    node.innerHTML = `
      <div class="black-overlay-wrapper">
        <div class="d-flex justify-content-center align-items-center h-100">
          <div class="spinner-border text-white" style="width: 3rem; height: 3rem" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    `;
    document.body.appendChild(node);
  }

  hide() {
    document.querySelector(`[data-role=${this.roleId}]`).remove();
  }
}
