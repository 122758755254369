// eslint-disable-next-line max-classes-per-file
const HeaderStore = {
  viewMode: 'special',
  isAllTimeBasicViewMode: false,
  deviceMode: null,
  isSpContentOpen: false,
  activeSubItemsId: null,
};

// Header inner elms
class HeaderTransformElm {
  constructor() {
    this.elm = null;
    this.specialPcModeClassNames = [];
    this.basicPcModeClassNames = [];
  }

  changeBasicView() {
    this.specialPcModeClassNames.forEach((c) => {
      this.elm.classList.remove(c);
    });
    this.basicPcModeClassNames.forEach((c) => {
      this.elm.classList.add(c);
    });
  }

  changeSpecialView() {
    this.basicPcModeClassNames.forEach((c) => {
      this.elm.classList.remove(c);
    });
    this.specialPcModeClassNames.forEach((c) => {
      this.elm.classList.add(c);
    });
  }

  render() {
    if (HeaderStore.viewMode === 'basic' && HeaderStore.deviceMode === 'pc') this.changeBasicView();
    if (HeaderStore.viewMode === 'special' && HeaderStore.deviceMode === 'pc') this.changeSpecialView();
  }
}

class HeaderLead extends HeaderTransformElm {
  constructor() {
    super();
    this.elm = document.querySelector('[data-role="site-header-lead"]');
    this.basicPcModeClassNames = ['d-none'];
    this.specialPcModeClassNames = [];
  }
}

class HeaderGnavWrap extends HeaderTransformElm {
  constructor() {
    super();
    this.elm = document.querySelector('[data-role="site-header-gnav-wrap"]');
    this.basicPcModeClassNames = ['order-lg-0'];
    this.specialPcModeClassNames = ['order-lg-1'];
  }
}

class HeaderNavsContainer extends HeaderTransformElm {
  constructor() {
    super();
    this.elm = document.querySelector('[data-role="site-header-navs-container"]');
    this.basicPcModeClassNames = ['pt-lg-0'];
    this.specialPcModeClassNames = ['pt-lg-3'];
  }
}

class HeaderGnav extends HeaderTransformElm {
  constructor() {
    super();
    this.elm = document.querySelector('[data-role="site-header-gnav"]');
    this.basicPcModeClassNames = [];
    this.specialPcModeClassNames = ['justify-content-between'];
  }
}

class HeaderLogoWrap extends HeaderTransformElm {
  constructor() {
    super();
    this.elm = document.querySelector('[data-role="site-header-logo-wrap"]');
    this.basicPcModeClassNames = ['order-lg-0'];
    this.specialPcModeClassNames = ['order-lg-4'];
  }
}

class HeaderLogo extends HeaderTransformElm {
  constructor() {
    super();
    this.elm = document.querySelector('[data-role="site-header-logo"]');
    this.basicPcModeClassNames = ['d-block'];
    this.specialPcModeClassNames = ['d-lg-none'];
  }
}

class HeaderSpecialLogo extends HeaderTransformElm {
  constructor() {
    super();
    this.elm = document.querySelector('[data-role="site-header-special-logo"]');
    this.basicPcModeClassNames = [];
    this.specialPcModeClassNames = ['d-lg-block'];
  }
}

class HeaderUtilNavWrap extends HeaderTransformElm {
  constructor() {
    super();
    this.elm = document.querySelector('[data-role="site-header-util-nav-wrap"]');
    this.basicPcModeClassNames = ['col-lg-auto', 'order-lg-1'];
    this.specialPcModeClassNames = ['col-lg-12', 'order-lg-0', 'text-right'];
  }
}

class HeaderUtilNav extends HeaderTransformElm {
  constructor() {
    super();
    this.elm = document.querySelector('[data-role="site-header-util-nav"]');
    this.basicPcModeClassNames = [];
    this.specialPcModeClassNames = ['mt-n3', 'mb-3'];
  }
}

class HeaderToggler {
  constructor() {
    this.self = document.querySelector('[data-role="site-header-toggler"]');
    this.barsIcon = this.self.querySelector('.js-site-header-toggler-bars');
    this.timesIcon = this.self.querySelector('.js-site-header-toggler-times');
    this.target = document.querySelector('[data-role="site-header-navs-container"]');

    this.self.addEventListener('click', (e) => {
      e.preventDefault();
      this.renderAndUpdateStore();
    });
  }

  changeIconToTimes() {
    this.barsIcon.classList.add('d-none');
    this.timesIcon.classList.remove('d-none');
  }

  changeIconToBars() {
    this.timesIcon.classList.add('d-none');
    this.barsIcon.classList.remove('d-none');
  }

  showTarget() {
    this.target.classList.remove('d-none');
  }

  hideTarget() {
    this.target.classList.add('d-none');
  }

  renderAndUpdateStore() {
    if (!HeaderStore.isSpContentOpen) {
      this.changeIconToTimes();
      this.showTarget();
      HeaderStore.isSpContentOpen = true;
    } else {
      this.changeIconToBars();
      this.hideTarget();
      HeaderStore.isSpContentOpen = false;
    }
  }
}

class HeaderSubItemOpener {
  constructor(elm) {
    this.self = elm;
    this.id = this.self.dataset.id;
    this.openers = document.querySelectorAll('[data-role="site-header-sub-items-opener"]');
    this.targets = document.querySelectorAll('[data-role="site-header-sub-items"]');
    this.target = document.querySelector(`[data-id="${this.id}"][data-role="site-header-sub-items"]`);
    this.selfPlusIcon = this.self.querySelector('.js-site-header-opener-plus');
    this.selfMinusIcon = this.self.querySelector('.js-site-header-opener-minus');

    this.self.addEventListener('click', (e) => {
      this.handleClick();
      return false;
    });

    // 伝播防止
    Array.prototype.slice.call(this.targets, 0).forEach((subNav) => {
      subNav.addEventListener('click', (e) => {
        e.stopPropagation();
        return false;
      });
    });

    this.self.addEventListener('mouseover', () => {
      this.handleMouseover();
    });
    this.self.addEventListener('mouseout', () => {
      this.handleMouseout();
    });
  }

  // For SP View
  handleClick() {
    if (HeaderStore.deviceMode === 'sp' && HeaderStore.isSpContentOpen === true) {
      this.hideAllTarget();
      this.changeAllIconToPlus();
      if (HeaderStore.activeSubItemsId !== this.id) {
        this.showTarget();
        this.changeIconToMinus();
        HeaderStore.activeSubItemsId = this.id;
      } else {
        this.changeIconToPlus();
        HeaderStore.activeSubItemsId = null;
      }
    }
  }

  // For PC View
  handleMouseover() {
    if (HeaderStore.deviceMode === 'pc') {
      this.hideAllTarget();
      this.showTarget();
    }
  }

  // For PC View
  handleMouseout() {
    if (HeaderStore.deviceMode === 'pc') {
      this.hideAllTarget();
    }
  }

  hideAllTarget() {
    Array.prototype.slice.call(this.targets, 0).forEach((target) => {
      target.classList.add('d-none');
    });
  }

  showTarget() {
    this.target.classList.remove('d-none');
  }

  hideTarget() {
    this.target.classList.add('d-none');
  }

  changeAllIconToPlus() {
    Array.prototype.slice.call(this.openers, 0).forEach((opener) => {
      opener.querySelector('.js-site-header-opener-plus').classList.remove('d-none');
      opener.querySelector('.js-site-header-opener-minus').classList.add('d-none');
    });
  }

  changeIconToPlus() {
    this.selfMinusIcon.classList.add('d-none');
    this.selfPlusIcon.classList.remove('d-none');
  }

  changeIconToMinus() {
    this.selfPlusIcon.classList.add('d-none');
    this.selfMinusIcon.classList.remove('d-none');
  }
}

class HeaderLangOpener {
  constructor() {
    this.elm = document.querySelector('.site-header-lang-nav-opener');
    this.target = document.querySelector('.site-header-lang-nav-list');

    this.state = {
      isShow: false,
    };

    this.elm.addEventListener('click', (e) => {
      e.preventDefault();
      if (this.state.isShow) {
        this.hideNav();
      } else {
        this.showNav();
      }
      return false;
    });
  }

  showNav() {
    this.target.classList.remove('d-none');
    this.state.isShow = true;
  }

  hideNav() {
    this.target.classList.add('d-none');
    this.state.isShow = false;
  }
}

class Header {
  constructor(isAllTimeBasicViewMode = false) {
    // config
    this.viewModeYbp = 450;
    this.deviceModeBp = 1132;
    // elements
    this.siteWrapper = document.querySelector('body');
    this.navsContainer = new HeaderNavsContainer();
    this.headerLead = new HeaderLead();
    this.gnavWrap = new HeaderGnavWrap();
    this.gnav = new HeaderGnav();
    this.logoWrap = new HeaderLogoWrap();
    this.logo = new HeaderLogo();
    this.specialLogo = new HeaderSpecialLogo();
    this.utilNavWrap = new HeaderUtilNavWrap();
    this.utilNav = new HeaderUtilNav();
    this.toggler = new HeaderToggler();
    this.openers = [];
    // [note] あとで追加された要素。暫定的にクラス化せずDOM Nodeとして管理
    this.langNavNode = document.querySelector('[data-role="site-header-lang-nav-wrap"]');
    Array.prototype.slice
      .call(document.querySelectorAll('[data-role="site-header-sub-items-opener"]'), 0)
      .forEach((e) => {
        this.openers.push(new HeaderSubItemOpener(e));
      });

    this.langOpener = new HeaderLangOpener();

    // Event

    window.addEventListener('load', () => {
      this.updatateLangNav();
    });

    window.addEventListener('scroll', () => {
      this.updateViewModeStore();
      this.updateDeviceModeStore();
      this.render();
      this.updatateLangNav();
      this.langOpener.hideNav();
    });

    window.addEventListener('resize', () => {
      this.updateViewModeStore();
      this.updateDeviceModeStore();
      this.render();
      // console.log('viewMode =>', HeaderStore.viewMode);
      // console.log('deviceMode =>', HeaderStore.deviceMode);
      this.langOpener.hideNav();
    });

    // store init
    // [note: 210514] ページによってヘッダーの見た目(モード)を固定したいという要望が
    // 上がったため追加。強制的にページのスクロールによるヘッダーのモード変更を拒否するためのオプション
    HeaderStore.isAllTimeBasicViewMode = isAllTimeBasicViewMode;
    if (isAllTimeBasicViewMode) HeaderStore.viewMode = 'basic';

    // init
    this.updateViewModeStore();
    this.updateDeviceModeStore();
    this.render();
  }

  updatateLangNav() {
    if (HeaderStore.viewMode === 'special') {
      this.langNavNode.classList.remove('d-none');
    } else {
      this.langNavNode.classList.add('d-none');
    }
  }

  updateDeviceModeStore() {
    if (window.innerWidth >= this.deviceModeBp) {
      HeaderStore.deviceMode = 'pc';
    } else {
      HeaderStore.deviceMode = 'sp';
    }
  }

  updateViewModeStore() {
    // const location = Math.max(
    //   document.scrollingElement.scrollTop,
    //   document.documentElement.scrollTop
    // );
    const location = document.documentElement.scrollTop;
    if (HeaderStore.isAllTimeBasicViewMode || location > this.viewModeYbp) {
      HeaderStore.viewMode = 'basic';
    } else {
      HeaderStore.viewMode = 'special';
    }
  }

  UpdateBodyClass() {
    this.siteWrapper.classList.remove('basic-pc-mode');
    this.siteWrapper.classList.remove('basic-sp-mode');
    this.siteWrapper.classList.remove('special-pc-mode');
    this.siteWrapper.classList.remove('special-sp-mode');
    this.siteWrapper.classList.add(`${HeaderStore.viewMode}-${HeaderStore.deviceMode}-mode`);
  }

  render() {
    this.gnavWrap.render();
    this.headerLead.render();
    this.navsContainer.render();
    this.gnav.render();
    this.logoWrap.render();
    this.logo.render();
    this.specialLogo.render();
    this.utilNavWrap.render();
    this.utilNav.render();
    Array.prototype.slice.call(this.openers, 0).forEach((opener) => {
      opener.changeIconToPlus();
      opener.hideTarget();
    });
    this.UpdateBodyClass();
  }
}

export default Header;
