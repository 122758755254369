/* eslint-disable */

// [note]
// 「たびくら」にも本スクリプトを転用しなければならない。
// たびくら環境ではes6構文を書くことができない
// ※ 正確には「書かないほうが無難」
// ※ ページヘッダー実装後、発覚
// そのためfooterに関しては初めからes5で書いておく

// ------------------------
// Store
// ------------------------

var FooterStore = function (args) {
  this.mainClassInstance = args.mainClassInstance || null;
  this.spBp = 768; // css sm size breakpoint
  this.activeOpenerId = null;
};

// ------------------------
// Main Class (Footer)
// ------------------------

var Footer = function () {
  this.store = new FooterStore({ mainClassInstance: this });

  // openers
  this.openersSelector = document.querySelectorAll(
    '[data-role="footer-menu-blob-opener"]'
  );
  this.openers = [];
  Array.prototype.slice.call(this.openersSelector, 0).forEach(
    function (opener) {
      this.openers.push(
        new FooterMenuBlobOpener({ store: this.store, node: opener })
      );
    }.bind(this)
  );

  // contents
  this.contentsSelector = document.querySelectorAll(
    '[data-role="footer-menu-blob"]'
  );
  this.contents = [];
  Array.prototype.slice.call(this.contentsSelector, 0).forEach(
    function (content) {
      this.contents.push(
        new FooterMenuBlob({ store: this.store, node: content })
      );
    }.bind(this)
  );

  // init views
  this.render();
};

Footer.prototype = {
  render: function () {
    this.openers.forEach(
      function (opener) {
        opener.changeIconToDown();
        if (opener.id === this.store.activeOpenerId) opener.changeIconToUp();
      }.bind(this)
    );
    this.contents.forEach(
      function (content) {
        content.hide();
        if (content.id === this.store.activeOpenerId) content.show();
      }.bind(this)
    );
  },
};

// ------------------------
// Opener
// ------------------------

var FooterMenuBlobOpener = function (args) {
  this.store = args.store;
  this.node = args.node;
  this.id = args.node.dataset.id;
  this.downIcon = args.node.querySelector(
    '.site-footer-nav__list-ttl-opener-down'
  );
  this.upIcon = args.node.querySelector('.site-footer-nav__list-ttl-opener-up');
  this.node.addEventListener(
    'click',
    function () {
      if (window.innerWidth <= this.store.spBp) {
        this.updateStore(this.id);
        this.store.mainClassInstance.render();
      }
    }.bind(this)
  );
};

FooterMenuBlobOpener.prototype = {
  updateStore: function (id) {
    if (this.store.activeOpenerId === id) {
      this.store.activeOpenerId = null;
    } else {
      this.store.activeOpenerId = id;
    }
  },
  changeIconToDown: function () {
    this.downIcon.classList.add('d-block');
    this.downIcon.classList.remove('d-none');
    this.upIcon.classList.add('d-none');
    this.upIcon.classList.remove('d-block');
  },
  changeIconToUp: function () {
    this.node.classList.add('is-active');
    this.upIcon.classList.add('d-block');
    this.upIcon.classList.remove('d-none');
    this.downIcon.classList.add('d-none');
    this.downIcon.classList.remove('d-block');
  },
};

// ------------------------
// Content
// ------------------------

var FooterMenuBlob = function (args) {
  this.store = args.store;
  this.node = args.node;
  this.id = args.node.dataset.id;
};

FooterMenuBlob.prototype = {
  show: function () {
    this.node.classList.remove('d-none');
  },
  hide: function () {
    this.node.classList.add('d-none');
  },
};

export default Footer;
