export default class Marker {
  constructor(opts = {}) {
    this.store = opts.store;
    this.node = opts.node;
    this.id = this.node.dataset.id;
    this.node.addEventListener('click', () => {
      this.handleClick();
    });
  }

  handleClick() {
    this.rewriteIdStore();
    this.store.masterInstance.render();
  }

  rewriteIdStore() {
    this.store.crId = this.id;
  }

  active() {
    this.node.classList.add(this.store.className.markerActive);
  }

  inactive() {
    this.node.classList.remove(this.store.className.markerActive);
  }
}
